<template lang="pug">
q-page.full-height.scroll
  .q-pa-md
    | Members
</template>

<style>
</style>

<script>
export default {};
</script>
